.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    z-index: 1001;
  }
  
  .form {
    display: flex;
    flex-direction: column;
      margin: 10px 0;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    }
    
    .input {
      padding: 5px;
      margin-bottom: 10px;
      width: calc(100% - 22px); /* Adjust width to account for padding */
      /* font-size: 16px; */
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box; /* Include padding and border in width */
    }
    
    .input:focus {
      border-color: #54b5f7;
      box-shadow: 0 0 8px #54b5f7;
    }
    
    .button {
      margin-top: 10px;
  
      padding: 10px 15px;
      font-size: 16px;
      color: white;
      background-color: #009879;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .button:hover {
      background-color: #00795f;
    }
    