:root {
  --background-dark: #060628;
  --background-light: #e0ecfa;
  --background-light2: #bac7de;
  --background-table-headers: #535a5b;
  --text-dark: #e0ecfa;
  --text-light: #060628;
  --text-secondary: #b3b3b3;
  --accent-primary: #0d6efd;
  --accent-secondary: #72bff2;
  --hover-state: #1a73e8;
  --active-state: #0056b3;
  --border-color: #323232;
  --error-color: #e57373;
  --warning-color: #ffb74d;
}

.App {
  font-size: 1rem;
  min-height: 100vh;
  background-color: var(--background-light);
  text-align: center;
  /* display: grid;
  grid-gap: 5px;
  grid-template-columns: 10vw auto;
  grid-template-rows: 10vh auto 5vh;
  grid-template-areas: "header header"
                        "navbar main"
                        "footer footer"; */
                      }

.Header {
  position: fixed;
  top: 0;
  height: 3.2rem;
  display: block;
  width: 100vw;
  /* grid-area: header;
  max-width: 100vw; */
  background-color: var(--background-dark);
  background-color: var(--background-dark);
  /* color: var(--text-dark); */
  /* display: flex; */
  
  /* font-size: calc(10px + 2vmin); */
  padding-top: 10px;
}

.Content {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  padding-top: 4rem;
  padding-bottom: 31px;
}

.Sidebar {
  /* grid-area: navbar; */
  width: 10rem;
  flex-shrink: 0;
  position: relative;
  word-wrap: break-word;
  color: var(--text-primary);
  min-height: calc(100vh - 7rem);
  background-color: var(--background-dark);
  padding: 10px;
  padding-bottom: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


.Main {
  flex: 1;
  width: auto;
  position: relative;
  background-color: var(--background-light);
  color: var(--text-light);
  text-align: left;
  padding: 10px;
  border-radius: 10px;


}

.Footer {
  position: fixed;
  bottom: 0;
  display: block;
  width: 100vw;
  height: 1rem;


  /* grid-template-areas: footer;
  grid-column: span 2; */
  text-align: center;
  padding-top: 5px;
  background-color: var(--background-dark);
  color: var(--text-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
