.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2a2a2a; /* Dark background */
  }
  
  .form {
    padding: 20px;
    background: #333333; /* Slightly lighter background for the form */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Darker shadow for more depth */
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #555; /* Lighter border for visibility */
    border-radius: 4px;
    color: #ddd; /* Light text for readability */
    background-color: #222; /* Dark input fields */
  }
  
  .button {
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #0066cc; /* A bright color for contrast */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #004999; /* Darken on hover */
  }
  
  .error {
    color: #ff6e67; /* Brighter color for errors for better visibility */
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .welcomeMessage {
    color: #ddd; /* Light color for text */
    margin-bottom: 20px; /* Space before input fields */
    text-align: center;
  }