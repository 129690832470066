  
  .options {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
    grid-area: right;
  }
  
  .options label {
    padding: 5px;
    display: block;
  }
  .options input {
    margin-left: 5px;
  }
  