.left {
  grid-area: left;
}

.table {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  table-layout: auto;
  text-indent: 0;
  /* border-color: inherit; */
  border-collapse: collapse;
}
.tableHeader {
  font-weight: 600;
  background-color: var(--background-table-headers);
  color: var(--text-dark);
  text-align: center;
  cursor: pointer;
}
.tableHeader td:nth-of-type(2) {
  text-align: left;
}
.tableRow td:nth-of-type(2) {
  text-align: left;
}

.tableRow {
  /* border-bottom: 1px solid black; */
  padding: 10px;
  text-align: left;
  text-align: center;
  border-collapse: collapse;
}
.tableRow:nth-child(even) {
  background-color: var(--background-light2);
}

.rowInput {
  max-width: 3rem;
}

.red > td > input .red > td > select {
  color: red;
}
