.main {
  display: grid;
  gap: 20px;
  grid-auto-rows: 96px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.table {
  /* Start at x1:y1 x=3 y=4 */
  grid-area: 1 / 1 / span 4 / span 3;
  overflow: auto;
}

.info {
  /* Start at x4:y1 x=1 y=3 */
  grid-area: 1 / 4 / span 3 / span 1;
}
