.course {
  /* display: grid; */
  /* grid-template-columns: 2fr 1fr; */
  /* column-gap: 5px; */
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 90rem 10rem;
  grid-template-areas: "left right";
  grid-gap: 30px;
  padding-bottom: 60px;
}
