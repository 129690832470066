.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  
  .thead {
    background-color: #54b5f7;
    color: #ffffff;
    text-align: left;
  }
  
  .th, .td {
    padding: 8px 8px;
    border: 1px solid #dddddd;
  }
  
  .tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .tbody tr:last-of-type {
    border-bottom: 2px solid #54b5f7;
  }
  
  .tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .filterInput {
    /* font-size: 16px; Larger font size for better readability */
    padding: 10px 10px; /* Ample padding for ease of input */
    margin-top: 10px;
    margin-right: 10px;
    /* margin-bottom: 10px; Space below the input field before the table starts */
    width: 100%; /* Full width to align with the table width */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    border: 2px solid #54b5f7; /* Solid border with a color that matches the table header */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    outline: none; /* Removes the default focus highlight */
  }
  
  .filterInput:focus {
    border-color: #006854; /* Darker shade when input is focused */
    box-shadow: 0 0 8px 0 #54b5f7; /* Subtle glow effect on focus */
  }

  .control {
    display: flex;
  }

  