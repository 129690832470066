.menu {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
}

.link{
    padding: 5px 20px;
    list-style: none;
    text-align: left;
}

.menu a {
    text-decoration: none;
    color: var(--text-dark);
}

.groupHeader {
    margin-top: 10px;
    color: var(--text-dark);
    border-top: 2px solid var(--background-light)
}