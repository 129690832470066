.header {
  
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  color: var(--text-dark);
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.logo {
  margin-left: 10px;
}

.logo img {
  width: 100px;
}

.title {
  font-size: 1.5rem;
  color: var(--text-primary);
}

.info {
    margin-right: 10px;
    font-size: 1rem;
}
